import React, { Component } from "react"

import Stanza from "./components/Stanza"

import styles from "./styles.module.less"

export default class LeadFromHome extends Component {
  render() {
    return (
      <div className={styles.LeadFromHome}>
        <Stanza>
          <span className={styles.header}>Thesis is a digital agency </span>
          <span className={styles.header}>that’s hell-bent on humanizing </span>
          <span className={styles.header}>
            the relationship between people and brands.
          </span>
        </Stanza>

        <Stanza>
          We’ve felt this unforgiving year. From the things we can't control:
          COVID. Wildfires. To the things we can absolutely do something about:
          Injustice.
        </Stanza>

        <Stanza>
          While we don't believe the flip of the calendar will suddenly flip the
          script, we do believe the work we do each day can play a part in
          progress. The digital products we make can help brands keep it real
          and relevant for the people enduring these unreal times, and the
          equity initiatives we exercise can educate our own house, helping us
          to stay aware, empathetic, and ever on course.
        </Stanza>

        <Stanza>
          Through our work and beyond our work, we’re committed to doing right
          by our people, our clients, and our community. And although our
          Diversity, Equity, and Inclusion (DEI) journey has been
          winding—tested, tried, and renewed across the year—it’s ongoing and
          everlasting.
        </Stanza>

        <div className={styles.closing}>
          <Stanza>
            So, on we go. Real people making real work. Step by very intentional
            step. Eyes forward, hearts all around, and upward as one.
          </Stanza>
        </div>

        <div style={{ height: "2000px" }}></div>
      </div>
    )
  }
}
