import React from "react"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

const Stanza = ({ children }) => {
  return (
    <Reveal>
      <p className={styles.Stanza}>{children}</p>
    </Reveal>
  )
}

export default Stanza
